/*
==========================
Fonts
** Fonts for Tailwind are defined in src/tailwind.config.js
==========================
 */
$fontSizeBase: 16px;
$lineHeightBase: (24/16);

$fontSoehne: "Soehne", "Helvetica Neue", Helvetica, Arial, sans-serif;
$fontDefault: $fontSoehne;

/*
==========================
Colors
** Fonts for Tailwind are defined in src/tailwind.config.js
==========================
 */
$colorBlack: #000000;
$colorWhite: #ffffff;
$colorBlue: #003054;
$colorBluePale: #314E69;
$colorBlueLight: #92A9BF;
$colorRed: #F73646;
$colorRedSharp: #E7192A;
$colorGray: #3F3F3F;
$colorGrayLight: #676767;
$colorGrayLighter: #F6F6F6;

$colorGrayAlt: #CCCCCC;

$colorText: $colorGray;
$colorBackground: $colorGrayLighter;

/*
==========================
Breakpoints
** If you change these, remember to also update tailwind.config.js!**
==========================
 */

$small: 440px;
$smallPlus: 600px;
$medium: 750px;
$mediumPlus: 980px;
$large: 1200px;
$largePlus: 1420px;
$xlarge: 1800px;

$breakpoints: (
    s: $small,
    sp: $smallPlus,
    m: $medium,
    mp: $mediumPlus,
    l: $large,
    lp: $largePlus,
    xl: $xlarge,
);

/*
==========================
Gutters, margins, max width
==========================
 */

$gutterMap: (
    min: 16px,
    s: 16px,
    sp: 16px,
    m: 16px,
    mp: 16px,
    l: 30px,
    lp: 30px,
    xl: 40px
);

$outerMarginsMap: (
    min: 24px,
    s: 30px,
    sp: 30px,
    m: 36px,
    mp: 36px,
    l: 48px,
    lp: 48px,
    xl: 60px
);

$maxContentWidth: 1440px;

// If different max widths are needed, comment this in and comment the variable above out.
//
// $maxContentWidth: (
//     min: 1280px,
//     s: 1280px,
//     sp: 1280px,
//     m: 1280px,
//     mp: 1280px,
//     l: 1280px,
//     lp: 1440px,
//     xl: 1600px
// );

/*
==========================
Grid config
==========================
 */
$grid-breakpointMap: $breakpoints;
$grid-breakpoints: (s, sp, m, mp, l, lp, xl);
$grid-columns: 12;
$grid-outer-margins: $outerMarginsMap;
$grid-gutters: $gutterMap;
$grid-flexbox: true;
$grid-pull-columns: 12;
$grid-push-columns: 12;
$grid-use-long-classnames: false;
$grid-use-em: false;
$grid-max-width: $maxContentWidth; //Should include support for multiple max-widths at different breakpoings. See NAF Digital
//$grid-max-width-map: $maxContentWidth; // Optional. Use if different max widths are needed at different breakpoints
