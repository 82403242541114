.bareas {

    &__image {

        padding-left: 0;
        padding-right: 0;

        img {
            top: 0; left: 0;
        }

        @include until(s) {
            width: calc(100% + #{(outerMargin() - (gutter() / 2)) * 2});
            margin-left: -(outerMargin() - (gutter() / 2));
        }

        @include between(s, m) {
            @include aspect(440, 300);
            width: calc(100% + #{(outerMargin(s) - (gutter(s) / 2)) * 2});
            margin-left: -(outerMargin(s) - (gutter(s) / 2));
            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        @include from(m) {

            .is-odd & > * {
                width: calc(100% + #{outerMargin(m)});
                margin-left: -(outerMargin(m));
            }

            .is-even & > * {
                width: calc(100% + #{outerMargin(m)});
                margin-right: -(outerMargin(m));
            }
        }

        @include from(l) {
            .is-odd & > * {
                width: calc(100% + #{outerMargin(l)});
                margin-left: -(outerMargin(l));
            }

            .is-even & > * {
                width: calc(100% + #{outerMargin(l)});
                margin-right: -(outerMargin(l));
            }
        }
    }

    &__arrow {
        transition: transform 0.3s;

        html.using-mouse a:hover &,
        html.using-mouse button:hover &,
        a:active &,
        button:active &,
        a:focus &,
        button:focus & {
            transform: translateX(10px);
        }

        svg {
            width: 17px;
            height: 13px;
        }
    }
}
