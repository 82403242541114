.page-footer {
    &__logo {
        width: 20px;
        height: 13px;
        @include from(l) {
            top: 2px;
        }
    }

    html.using-mouse & a:hover {
        color: $colorWhite;
    }
}
