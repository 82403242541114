.cta-widget {

    &__image {

        $image: &;

        @include until(m) {
            height: 250px;
        }

        @include from(m) {
            min-height: 420px;
        }

        @include breakpoint((orientation landscape)) {
            min-height: 50vh;
        }

        svg {
            width: 15px;
            height: 15px;
            transition: transform 0.3s;

            @include from(l) {
                width: 24px;
                height: 24px;
            }
        }

        &-overlay {
            transition: opacity 0.3s;
            html.using-mouse #{$image}:hover & {
                opacity: 0;
            }
        }

    }

    &__text {

        @include until(m) {
            min-height: 250px;
        }

        html.using-mouse & {
            transition: background-color 0.3s;
        }

        &:hover {
            background-color: $colorRedSharp;
        }

        &-copy {
            max-width: 38ch;
        }
    }

}
