.page-header {

    $this: &;
    $inner: &;

    transition: opacity 0.3s $easeInQuad;

    html.js:not(.js-error) &[data-component]:not([data-component-booted]) {
        opacity: 0;
    }

    &__inner {
        [data-component-booted] &,
        [data-component]:not([data-component-booted]) :target & {
            position: fixed;
        }
        @include until(l) {
            .js-menu-open &,
            html.js-error :target &,
            [data-component]:not([data-component-booted]) :target & {
                position: fixed;
                height: 100%;
            }
        }
    }

    &__bar {

        padding-top: 28px;
        padding-bottom: 18px;

        background-color: transparent;
        transition: transform 0.5s, background-color 0.3s, color 0.3s;

        .js-scrolled & {
            pointer-events: auto;
            transform: translate(0, -8px);
            background-color: $colorWhite;
            color: $colorText;

            @include from(l) {
                transform: translate(0, -20px);
            }
        }

        @include until(l) {
            .js-menu-open &,
            html.js-error :target &,
            [data-component]:not([data-component-booted]) :target & {
                color: $colorWhite;
                background-color: transparent;
                transform: translate(0, 0);
            }
        }

        @include from(s) {
            padding-top: 32px;
        }

        @include from(m) {
            padding-top: 40px;
        }

        @include from(l) {
            padding-top: 48px;
            padding-bottom: 25px;
        }
    }

    &__branding {

        svg path {
            transition: fill 0.3s;
        }

        .bg-blue #{$this}:not(.js-scrolled) &,
        .js-menu-open &,
        html.js-error :target &,
        [data-component]:not([data-component-booted]) :target & {
            color: $colorWhite;
            svg path {
                fill: currentColor !important;
            }
        }

        #{$this}.text-white:not(.js-scrolled) & svg path[fill="#003054"] {
            fill: currentColor !important;
        }

        &-logo {
            width: 64px;
            height: 14px;

            @include from(m) {
                width: 78px;
                height: 17px;
            }

            @include from(l) {
                width: 106px;
                height: 23px;
                margin-top: -2px;
            }
        }
    }

    &__burger {
        width: 16px;
        height: 14px;

        &-line {
            height: 2px;
            transition: transform 0.3s, opacity 0.1s;
            &:first-child {
                top: 0;
            }
            &:nth-child(2) {
                top: 6px;
            }
            &:last-child {
                bottom: 0;
            }
        }

        [data-component]:not([data-component-booted]) :target &-line,
        html.js-error :target &-line,
        .js-menu-open &-line {
            &:first-child {
                transform: translate(0, 6px) rotate(45deg);
            }
            &:nth-child(2) {
                transform: scale(0, 0);
                opacity: 0;
            }
            &:last-child {
                transform: translate(0, -6px) rotate(-45deg);
            }
        }

        [data-component]:not([data-component-booted]) :target & [data-closebtn][hidden] {
            display: block !important;
        }

        @include from(s) {
            width: 20px;
        }
    }

    // Site switcher (desktop only)
    &__sites {

        &-caret {
            width: 7px;
            height: 5px;
        }

        &-list {
            width: 50px;
            left: 50%;
            margin-left: -6px;
            transform: translate(-50%, 0);
            display: none;
            html.no-js &:target,
            html.js-error &:target,
            .js-is-open & {
                display: block;
            }

            li {
                transition: transform 0.3s 0s ease-out;
                color: $colorText;
                background-color: $colorWhite;
            }
        }

    }

    // Menu (mobile only)
    &__menu {

        html.js-error :target &[hidden],
        [data-component]:not([data-component-booted]) :target &[hidden] {
            display: block !important;
        }

        &-item {
            @include until(m) {
                @include breakpoint((orientation landscape)) {
                    width: 50%;
                }
            }
        }
    }

}
