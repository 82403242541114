/*

    @font-face declarations

    Paths to url() should be relative to the SASS entrypoint (base.scss), i.e. "../fonts/fontname.woff2"

 */

/*@font-face {
    font-family: 'MyWebFont';
    font-weight: normal;
    font-style: italic;
    src: url('../fonts/webfont.woff2') format('woff2'), !* Super Modern Browsers *!
    url('../fonts/webfont.woff') format('woff'), !* Pretty Modern Browsers *!
    url('../fonts/webfont.ttf') format('truetype') !* Safari, Android, iOS *!
;
}*/


@font-face {
    font-family: 'Soehne';
    src:    url('../fonts/soehne/soehne-web-buch.eot'); /* IE9 Compat Modes */
    src:    url('../fonts/soehne/soehne-web-buch.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/soehne/soehne-web-buch.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/soehne/soehne-web-buch.woff') format('woff'); /* Pretty Modern Browsers */
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Soehne';
    src:    url('../fonts/soehne/soehne-web-buch-kursiv.eot'); /* IE9 Compat Modes */
    src:    url('../fonts/soehne/soehne-web-buch-kursiv.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/soehne/soehne-web-buch-kursiv.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/soehne/soehne-web-buch-kursiv.woff') format('woff'); /* Pretty Modern Browsers */
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Soehne';
    src:    url('../fonts/soehne/soehne-web-halbfett.eot'); /* IE9 Compat Modes */
    src:    url('../fonts/soehne/soehne-web-halbfett.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/soehne/soehne-web-halbfett.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/soehne/soehne-web-halbfett.woff') format('woff'); /* Pretty Modern Browsers */
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Soehne';
    src:    url('../fonts/soehne/soehne-web-halbfett-kursiv.eot'); /* IE9 Compat Modes */
    src:    url('../fonts/soehne/soehne-web-halbfett-kursiv.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/soehne/soehne-web-halbfett-kursiv.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/soehne/soehne-web-halbfett-kursiv.woff') format('woff'); /* Pretty Modern Browsers */
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}
