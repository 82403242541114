::-moz-selection {
    background: $colorBlueLight;
    color: $colorBlue;
}

::selection {
    background: $colorBlueLight;
    color: $colorBlue;
}

[hidden]:not(:target) {
    display: none !important;
}

button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    border-radius: 0;
    color: currentColor;
}

a, button {
    svg {
        pointer-events: none;
    }
}

blockquote {
    display: block;
    margin: 0;
    cite {
        font-style: normal;
    }
}

html.no-objectfit img,
html.no-objectfit video {
    object-position: 50% 50% !important;
}

details,
summary {
    * {
        box-sizing: border-box;
    }
}

details > summary {
    list-style: none;
    cursor: pointer;
}
details > summary::-webkit-details-marker {
    display: none;
}

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

table {
    border-spacing: 0;
    tr, th, td {
        padding: 0;
    }
    th {
        font-weight: normal;
    }
    td, th {
        text-align: left;
        vertical-align: top;
    }
}

svg {
    display: block;
    width: 100%;
    height: 100%;
}

html.js:not(.js-error):not(.is-live-preview) [data-reveal]:not(.js-reveal) {
    opacity: 0;
}

.hover\:zoom {
    overflow: hidden;
    html.using-mouse & > * {
        transition: transform 0.75s $easeOutQuint;
        transform-origin: center center;
    }
    html.using-mouse &:hover > * {
        transform: scale(1.04, 1.04);
    }
    html.using-mouse &:hover {
        z-index: 5;
    }
}

.hover\:red {
    html.using-mouse & {
        transition: color 0.3s, box-shadow 0.3s, fill 0.3s;
    }
    html.using-mouse &:hover,
    &:active {
        color: $colorRed;
    }
}

.hover\:white {
    html.using-mouse & {
        transition: color 0.3s, box-shadow 0.3s, fill 0.3s;
    }
    html.using-mouse &:hover,
    &:active {
        color: $colorWhite;
    }
}

::-moz-selection {
    background: $colorBluePale;
    color: $colorWhite;
}

::selection {
    background: $colorBluePale;
    color: $colorWhite;
}

// Stop double-tap-to-zoom on buttons and links
a, button {
    touch-action: manipulation;
}

// Reveal effect
html.js:not(.js-error):not(.is-live-preview) [data-reveal]:not(.js-reveal) {
    opacity: 0;
}

// Intrinsic images
@supports (--custom:property) {
    *[style*="--ratio"] {
        height: 0;
        padding-bottom: calc(100% / (var(--ratio-min)));
        @each $breakpoint in map_keys($breakpoints) {
            @include from($breakpoint) {
                padding-bottom: calc(100% / (var(--ratio-#{$breakpoint})));
            }
        }
        picture, img {
            position: absolute;
            top: 0; left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
