.timeline {

    //width: calc(100% + 18px);
    //margin-left: -9px;

    &__nav {

        opacity: 0;

        .flickity-viewport {
            overflow: visible;
        }

        .flickity-slider {
            display: flex;
            justify-content: space-between;
            > * {
                position: relative !important;
                left: auto !important;
                &:first-child::before {
                    content: "";
                    display: block;
                    width: 1000vw;
                    height: 120px;
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%, 0);
                    top: -80px;
                    @include from(m) {
                        height: 130px;
                    }
                    @include from(l) {
                        height: 140px;
                    }
                }
            }
        }
    }

    &__navbtn {

        width: 24px;
        height: 24px;
        top: 50%;
        margin-top: -12px;

        transition: transform 0.1s, opacity 0.3s;

        &.is-disabled {
            opacity: 0.3;
            cursor: default;
        }

        &.is-hidden {
            opacity: 0;
            pointer-events: none;
        }

        html.using-mouse &:not(.is-disabled):active > * {
            transform: scale(0.9, 0.9);
        }

        html.using-mouse &:not(.is-disabled):hover {
            svg {
                transform: translate(5px, 0);
            }
        }

        &--prev {
            right: 100%;
            transform: scaleX(-1);
            margin-right: 8px;
            @include from(m) {
                margin-right: 14px;
            }
        }

        &--next {
            left: 100%;
            margin-left: 8px;
            @include from(m) {
                margin-left: 14px;
            }
        }

        svg {
            width: 14px;
            height: 14px;
            transition: transform 0.3s;
        }
    }

    &__track {
        height: 3px;
        top: 50%;
        margin-top: -2px;
        margin-left: 10px;
        width: calc(100% - 20px);

        @include from(m) {
            margin-top: -1px;
        }

        &-bar {
            transform: scale(0, 1);
            transform-origin: left center;
        }
    }

    &__year {

        min-width: 1px;
        height: 16px;
        transition: color 0.3s;

        &.is-active,
        &.is-past {
            color: $colorRed;
        }

        @include from(m) {
            height: 24px;
        }

        &--item {

            min-width: 34px;
            transition: opacity 0.3s, transform 0.3s $easeOutBack;

            html.using-mouse &:not(.is-active):not(.is-past):hover {
                color: $colorText;
            }

            &.is-hidden {
                opacity: 0;
                transform: scale(0, 0);
                transition: opacity 0.1s, transform 0.1s linear;
                button {
                    pointer-events: none;
                }
            }

            @include from(m) {
                min-width: 42px;
            }
        }

        &-label {
            transform: translate(-50%, 0);
            transition: color 0.3s;

            html.using-mouse button:hover &,
            .is-active & {
                color: $colorText;
            }
        }

        &-dot {
            width: 16px;
            height: 16px;

            transition: transform 0.1s;

            button:active & {
                transform: scale(0.9, 0.9);
            }

            html.using-mouse button:hover:not(:active):not(.is-active) & {
                transform: scale(1.1, 1.1);
            }

            > * {

                width: calc(100% + 4px);
                height: calc(100% + 4px);
                top: -2px; left: -2px;
                transform: scale(0, 0);
                transition: transform 0.1s;

                .is-active & {
                    transform: scale(1, 1);
                }

                @include from(m) {
                    width: calc(100% - 6px);
                    height: calc(100% - 6px);
                    top: 3px;
                    left: 3px;
                }
            }

            @include from(m) {
                width: 24px;
                height: 24px;
            }
        }

    }

    &__slides {
        width: calc(100% + 12px);
        margin-left: -6px;
        @include from(m) {
            width: calc(100% + 16px);
            margin-left: -8px;
        }
        @include from(l) {
            width: calc(100% + 24px);
            margin-left: -12px;
        }
    }

    &__slide {
        width: calc(100% - #{outerMargin() * 2});
        > * {
            border-left: 6px solid white;
            border-right: 6px solid white;
            @include from(m) {
                border-left: 8px solid white;
                border-right: 8px solid white;
            }
            @include from(l) {
                border-left: 12px solid white;
                border-right: 12px solid white;
            }
        }
        @each $breakpoint in map-keys($breakpoints) {
            @include from($breakpoint) {
                width: calc(100% - #{outerMargin($breakpoint) * 2});
            }
        }
        @include from($maxContentWidth + (outerMargin(xl) * 3)) {
            width: $maxContentWidth + (outerMargin(xl) * 2);
        }
        transition: opacity 0.5s $easeOutCubic;
        &:not(.is-selected) {
            opacity: 0.65;
        }

        &-text {
            max-width: 480px;
            p:not(:last-child) {
                margin-bottom: 0.75em;
            }
        }
    }

    .flickity-viewport,
    .slideshow__wrap {
        overflow: visible;
    }

}
