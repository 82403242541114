.featured-companies {

    &__filter {
        box-shadow: inset 0 0 white, inset 0 -1px transparent;
        transition: box-shadow 0.15s ease-out, color 0.15s ease-out;
        html.using-mouse &:hover,
        &:active,
        &:focus {
            box-shadow: inset 0 0 white, inset 0 -1px currentColor;
        }
        &.is-active {
            color: $colorGray;
            box-shadow: inset 0 0 white, inset 0 -1px currentColor;
        }
    }

}
