.slideshow {

    &__inner {
        max-height: 50vh;
    }

    &__nextprev {

        width: 30px;
        height: 30px;
        top: 50%;
        margin-top: -15px;

        transition: background-color 0.3s, color 0.3s, transform 0.1s, opacity 0.3s;

        opacity: 0.8;

        html.using-mouse &:hover,
        &:active {
            opacity: 1;
        }

        &:active {
            transition: none;
        }

        &.is-hidden {
            opacity: 0;
            pointer-events: none;
        }

        html.using-mouse &:active {
            transform: scale(0.9, 0.9);
        }

        svg {
            width: 14px;
            height: 14px;
        }

        &--prev {
            left: outerMargin() + 15px;
            svg {
                transform: scaleX(-1);
            }
        }
        &--next {
            right: outerMargin() + 15px;
        }

        @include from(s) {
            &--prev {
                left: outerMargin(s) + 20px;
            }
            &--next {
                right: outerMargin(s) + 20px;
            }
        }

        @include from(m) {

            width: 40px;
            height: 40px;
            margin-top: -20px;

            &--prev {
                left: outerMargin(m) + 30px;
            }
            &--next {
                right: outerMargin(m) + 30px;
            }
        }

        @include from(l) {
            &--prev {
                left: outerMargin(l) + 50px;
            }
            &--next {
                right: outerMargin(l) + 50px;
            }
        }
    }

    &__dot {
        width: 10px;
        height: 10px;
        background-color: #CCCCCC;
        transition: background-color 0.3s;

        &.is-active {
            background-color: $colorRed;
        }

        html.using-mouse &:not(.is-active):hover,
        &:not(.is-active):active,
        &:not(.is-active):focus {
            background-color: $colorGrayLight;
        }
    }

    html.no-js &__wrap,
    html.js-error &__wrap {
        @include scrollableX;
    }
}
