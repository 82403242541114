.article {

    .component {
        padding: 0;
        margin: 40px 0;

        @include from(m) {
            margin: 80px 0;
        }
    }

}
