.company-info {

    &__logo {
        img {
            width: auto;
            height: 60px;

            @include from(m) {
                height: 80px;
            }
        }
    }

}
