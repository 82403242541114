// Missing something? It might've been converted to a custom Tailwind utility
// Check ./src/css/tailwind/utilities

// There's also a chance the utility you're looking for is covered by the default utilities in Tailwind
// https://tailwindcss.com/docs

.u {
    &-wrap {
        width: 100%;
        max-width: maxWidth() + (outerMargin()*2);
        padding-left: outerMargin();
        padding-right: outerMargin();
        margin-left: auto;
        margin-right: auto;

        &--bleed {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }

        @each $breakpoint in map-keys($breakpoints) {
            @include from($breakpoint) {
                padding-left: outerMargin($breakpoint);
                padding-right: outerMargin($breakpoint);
                max-width: maxWidth($breakpoint) + (outerMargin($breakpoint)*2);
            }
        }
    }

    &-ellipsis {
        @include ellipsis;
    }

    &-jsonly {
        html.no-js & {
            display: none !important;
        }
    }

    &-nojs {
        html.js & {
            display: none !important;
        }
    }

    &-hit {
        // Note: The element needs position: relative
        &::before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            min-width: 44px;
            min-height: 44px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &-link,
    a &-link,
    button &-link {
        box-shadow: inset 0 0 white, inset 0 -1px transparent;
        padding-bottom: 2px;
        margin-bottom: -2px;
        transition: box-shadow 0.3s ease-out;// color 0.15s ease-out;
        &:active,
        a:active &,
        html.using-mouse &:hover,
        html.using-mouse a:hover & {
            box-shadow: inset 0 0 white, inset 0 -1px currentColor;
        }
        &:active,
        a:active & {
            padding-bottom: 1px;
            margin-bottom: -1px;
        }
        &--inv {
            box-shadow: inset 0 0 white, inset 0 -1px currentColor;
            &:active,
            a:active &,
            html.using-mouse &:hover,
            html.using-mouse a:hover & {
                box-shadow: inset 0 0 white, inset 0 -1px transparent;
            }
        }
    }

    &-click {
        html.using-mouse &:active,
        html.using-mouse a:active &,
        html.using-mouse button:active & {
            transform: scale(0.8, 0.8);
        }
    }
}
