.accordions {
    &__content {
        html.no-js &[hidden]:target,
        html.js-error &[hidden]:target {
            display: block !important;
        }
    }

    [data-toggle] {
        transition: color 0.3s;
        svg {
            transition: transform 0.3s;
            width: 8px;
            height: 13px;
        }
    }

    html.using-mouse .text-white & [data-toggle]:hover,
    .text-white & [data-toggle][aria-expanded="true"] {
        color: $colorBlueLight;
    }

    html.using-mouse & [data-toggle]:hover svg {
        transform: translateX(10px);
    }

    [data-toggle][aria-expanded="true"] svg {
        transform: rotate(90deg) !important;
    }
}
