.hero {

    $this: &;

    &--hasimage {
        height: 100vh; // Would like to use min-height here, but IE11 won't play along
        @supports (--custom:property) {
            height: calc(var(--vh, 1vh) * 100);
        }
    }

    html.objectfit & {
        .lazyload, .lazyloading {
            transform: scale(1.03, 1.03) translate(0, 1vh);
            transform-origin: center bottom;
        }

        .lazyloaded {
            transform: scale(1, 1) translate(0, 0);
            transform-origin: center bottom;
            transition: opacity 0.5s $easeInSine, transform 1s $easeOutQuad;
        }
    }

    &__inner > * {
        transition: opacity 0.3s $easeInQuad;
    }

    html.js:not(.js-error) &:not([data-component-booted]) &__inner > * {
        opacity: 0;
    }

    &__navbar {
        height: 60px;
    }

    & + div .component:first-child {
        margin-top: -20px;
        @include from(s) {
            margin-top: -60px;
        }
    }
}
