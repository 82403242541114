.page-intro {

    html.objectfit & {
        .lazyload, .lazyloading {
            transform: scale(1.02, 1.02) translate(0, 1vh);
            transform-origin: center bottom;
        }
        .lazyloaded {
            transform: scale(1, 1) translate(0, 0);
            transform-origin: center bottom;
            transition: opacity 0.5s $easeInSine, transform 1s $easeOutQuad;
        }
    }

    &--hastext &__text > * {
        @include breakpoint((orientation portrait)) {
            min-height: 35vh;
        }
        @include breakpoint((orientation landscape)) {
            min-height: 45vh;
        }
    }

    &__text {
        transition: opacity 0.5s $easeInSine;
    }

    html.js:not(.js-error) &:not([data-component-booted]) &__text {
        opacity: 0;
    }
}
