.link {

    &__arrow {

        $this: &;
        transition: transform 0.3s;

        top: 0.05em;

        &:not(#{$this}--down) {
            html.using-mouse a:hover &,
            html.using-mouse button:hover &,
            a:focus &,
            button:focus & {
                transform: translateX(0.3em);
            }
        }

        &--down {
            html.using-mouse a:hover &,
            html.using-mouse button:hover &,
            a:focus &,
            button:focus & {
                transform: translateY(0.3em);
            }
            svg {
                transform: rotate(90deg);
            }
        }

        &--reverse:not(#{$this}--down) {
            html.using-mouse a:hover &,
            html.using-mouse button:hover &,
            a:focus &,
            button:focus & {
                transform: translateX(-0.3em);
            }
            svg {
                transform: scaleX(-1);
            }
        }

        svg {
            width: 0.75em;
            height: 0.75em;
            transition: fill 0.3s;
        }
    }
}
