.wysiwyg {

    p, ul, ol {
        margin-bottom: (22/16) * 1.1em;
        letter-spacing: 0.0125em;

        [class*="tracking-"] & {
            letter-spacing: inherit;
        }

        & + h1,
        & + h2,
        & + h3 {
            margin-top: (22/16) * 1.1em;
        }

        & + h4,
        & + h5,
        & + h6 {
            margin-top: (22/16) * 1.6em;
        }
    }

    ul {
        list-style: disc;
    }

    ol {
        list-style: decimal;
    }

    ul, ol {
        padding-left: 1em;
    }

    li {
        display: list-item;
        margin-bottom: 0.5em;
        padding-left: 0.25em;
    }

    a:not(.u-link) {
        text-decoration: underline;
        text-decoration-color: currentColor;
        transition: text-decoration-color 0.3s, color 0.3s;
        &:focus,
        a:focus &,
        html.using-mouse &:hover,
        html.using-mouse a:hover & {
            text-decoration-color: transparent;
            color: $colorRed;
        }
    }

    h1,h2,h3 {
        font-size: (21/16) * 1em;
        line-height: 1.6;
        margin-top: 0;
        margin-bottom: (22/16) * 0.75em;
        font-weight: normal;
        letter-spacing: 0.0125em;
        [class*="tracking-"] & {
            letter-spacing: inherit;
        }
    }

    h4,h5,h6 {
        font-weight: bold;
        margin-bottom: (22/16) * 0.5em;
        letter-spacing: 0.0125em;
        [class*="tracking-"] & {
            letter-spacing: inherit;
        }
    }

    & > *:last-child {
        margin-bottom: 0;
    }

    b, strong {
        font-weight: 600;
    }

    em, i {
        font-style: italic;
    }
}
