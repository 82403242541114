.video-embed {

    &__poster {
        &-icon {
            > * {
                transition: transform 0.3s $easeInOutQuad;
                html.using-mouse a:hover & {
                    transform: scale(1.1, 1.1);
                }
            }
        }
        svg {
            width: 32px;
            height: 36px;
        }
    }
}
