.modal {

    &__closebtn {

        html.using-mouse &:active {
            svg {
                transform: scale(0.9, 0.9);
            }
        }

        svg {
            display: block;
            width: 24px;
            height: 24px;

            @include from(m) {
                width: 30px;
                height: 30px;
            }
        }
    }

    &__content {
        overflow-y: scroll;
        > * {
            width: 100%;
            min-height: calc(100% + 1px);
        }
    }

    &__spinner {
        opacity: 0;
        transition: opacity 0.3s;
        .js-is-loading & {
            opacity: 1;
        }
    }
}
