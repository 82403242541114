.component {

    $this: &;

    &:not(#{$this}--colored):first-child {
        padding-top: 60px;
        @include from(s) {
            padding-top: 80px;
        }
        @include from(m) {
            padding-top: 100px;
        }
        @include from(l) {
            padding-top: 120px;
        }
        @include from(xl) {
            padding-top: 140px;
        }
    }

    &--colored:last-child {
        padding-bottom: 0;
    }

    &--colored + &--colored {
        margin-top: -60px;
        @include from(s) {
            margin-top: -80px;
        }
        @include from(m) {
            margin-top: -100px;
        }
        @include from(l) {
            margin-top: -120px;
        }
        @include from(xl) {
            margin-top: -140px;
        }
    }
}
